import React, { Component } from 'react'
import Link from '../utils/link'

import { Logo, LogoMark } from '../components/icons'

class Header extends Component {

  state = {
    offCanvas: false
  }

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false })
  }

  _toggleOffCanvas = () => {
    this.setState({ offCanvas: !this.state.offCanvas })
  }

  render() {

    let { offCanvas } = this.state

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active'
    }

    return (
      <>
        <header className='header'>
          <div className='header__inner'>
            <div className='col'>

              <span className='mobile-spacer'></span>

              <Link to='/' title='Atypical' className='header__logo' {...props}>
                <div className='logo-mark' >
                  <LogoMark color={'#000'} />
                </div>

                <div className='logo' >
                  <Logo />
                </div>
              </Link>

              <div className='mobile-logo'>
                <Link to='/' title='Atypical' {...props}>
                  <LogoMark color={'#000'} />
                </Link>
              </div>

              <button onClick={this._toggleOffCanvas} className={ offCanvas ? 'header__hamburger active' : 'header__hamburger' }>
                  <span className='lines'></span>
              </button>

            </div>

            <div className='col'>
              <div className='header__center'>
                <nav className='header__nav'>
                  <ul>
                    <li><Link to='/projects' {...props}>Projects</Link></li>
                    <li><Link to='/community' {...props}></Link></li>
                    {/* <li><Link to='/store' {...props}>Store</Link></li> */}
                    <li><Link to='/contact' {...props}>Contact</Link></li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </header>

        <div className={`off-canvas ${ offCanvas && 'off-canvas--active' }`}>
          <div className='off-canvas__inner'>
          <div className='off-canvas__logo-container'>
            <div className='off-canvas__logo'>
              <Logo />
            </div>

              <button onClick={this._toggleOffCanvas} className={ offCanvas ? 'header__hamburger canvas-menu active' : 'header__hamburger canvas-menu' }>
                  <span className='lines'></span>
              </button>
          </div>


            <nav className='off-canvas__nav'>
              <ul>
                <li><Link to='/projects' {...props}>Projects</Link></li>
                <li><Link to='/community' {...props}>Community</Link></li>
                {/* <li><Link to='/store' {...props}>Store</Link></li> */}
                <li><Link to='/contact' {...props}>Contact</Link></li>
              </ul>
            </nav>

            <nav className='off-canvas__nav-footer'>
              <ul>
                <li><Link to="https://www.instagram.com/atypical.practice/">Instagram</Link></li>
                <li><Link to="/newsletter-signup" {...props}>Sign up to our newsletter</Link></li>
                <li><Link to='/privacy-policy' {...props}>Privacy Policy</Link></li>
              </ul>
            </nav>
          </div>
        </div>
      </>
    )
  }
}

export default Header
