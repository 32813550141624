import React, { Component } from 'react'
import Link from '../utils/link'

class Footer extends Component {
  render() {
    return (
      <footer className='footer'>

        {/* {console.log(footer)} */}

        <div className='footer__inner'>
          <div className='col'>
            <nav className='footer__nav'>
              <ul>
                <li>
                  <Link to="/newsletter-signup">Sign up to our newsletter</Link>
                </li>
              </ul>
            </nav>
          </div>

          <div className='col'>
            <nav className='footer__nav'>
              <ul>
                <li><Link to="https://www.instagram.com/atypical.practice/">Instagram</Link></li>
                <li><Link to='/privacy-policy'>Privacy Policy</Link></li>
                <li><span>&copy; Atypical {new Date().getFullYear()}</span></li>
              </ul>
            </nav>
          </div>

        </div>
      </footer>
    )
  }
}

export default Footer