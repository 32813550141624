import React, { Component } from 'react'

class Logo extends Component {
  render() {
    return (
      <svg x="0px" y="0px" viewBox="0 0 1920 391.1">
      <g>
        <path d="M1727.7,383.1H1920v-53.6h-138.8V8h-53.6V383.1z M1523.7,383.1l53.6-375l53.6,375h54.1L1631.3,8h-108.2l-53.6,375H1523.7z
          M1116.9,273.2c10,23.9,23.7,44.6,41,61.9c17.3,17.3,37.9,31,61.9,41c23.9,10,49.8,15,77.7,15c25.4,0,49.8-4.4,73.4-13.1
          s44.6-22.1,63.2-39.9V235.7c-4.3,15.7-10.8,29.8-19.6,42.3c-8.8,12.5-19.1,23.2-31.1,32.1c-12,8.9-25.3,15.7-39.9,20.4
          c-14.6,4.6-30,7-46.1,7c-20.4,0-39.2-3.6-56.5-10.7c-17.3-7.1-32.3-17.1-45-29.7c-12.7-12.7-22.6-27.7-29.7-45
          c-7.1-17.3-10.7-36.2-10.7-56.5s3.6-39.2,10.7-56.5c7.1-17.3,17.1-32.3,29.7-45c12.7-12.7,27.7-22.6,45-29.7
          c17.3-7.1,36.2-10.7,56.5-10.7c16.1,0,31.4,2.3,46.1,7c14.6,4.6,27.9,11.4,39.9,20.4c12,8.9,22.3,19.6,31.1,32.1
          c8.7,12.5,15.3,26.6,19.6,42.3V53c-18.6-17.9-39.6-31.2-63.2-39.9c-23.6-8.7-48-13.1-73.4-13.1c-27.9,0-53.8,5-77.7,15
          c-23.9,10-44.6,23.7-61.9,41c-17.3,17.3-31,38-41,61.9c-10,23.9-15,49.8-15,77.7S1106.9,249.3,1116.9,273.2 M1008.2,383.1h53.6V8
          h-53.6V383.1z M724.2,168.8V61.6h143c15.4,0,28.4,5,39.1,15c10.7,10,16.1,22.9,16.1,38.6c0,15.7-5.4,28.6-16.1,38.6
          c-10.7,10-23.8,15-39.1,15H724.2z M670.6,383.1h53.6V222.3h142.5c15,0,29.2-2.8,42.6-8.3c13.4-5.5,25-13.1,34.8-22.8
          c9.8-9.6,17.6-21,23.3-34c5.7-13,8.6-27.2,8.6-42.6c0-15.4-2.9-29.6-8.6-42.6c-5.7-13-13.5-24.3-23.3-33.8
          c-9.8-9.5-21.4-16.9-34.8-22.2c-13.4-5.4-27.6-8-42.6-8H670.6V383.1z M429.9,8l80.4,266.8v108.2h53.6V274.8L644.2,8H589L558,118.9
          c-2.9,10.4-5.5,22-8,34.8c-2.5,12.9-4.6,26-6.4,39.4c-1.8,13.4-3.1,26.7-4,39.9c-0.9,13.2-1.3,25.2-1.3,35.9h-1.6
          c0-10.7-0.5-22.7-1.6-35.9c-1.1-13.2-2.5-26.5-4.3-39.9c-1.8-13.4-3.9-26.5-6.4-39.4c-2.5-12.9-5.2-24.5-8-34.8L485.1,8H429.9z
          M213,8v53.6h69.6v321.5h53.6V61.6h69.6V8H213z M54.1,383.1L107.7,8l53.6,375h54.1L161.8,8H53.6L0,383.1H54.1z"/>
      </g>
      </svg>

    )
  }
}

class LogoMark extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="55" height="60" viewBox="0 0 55 60" fill="none">
        <path d="M15.2519 20.6881C17.275 23.9193 18.9602 27.233 20.3089 30.6323C21.6576 34.0317 22.332 37.3316 22.332 40.5337C22.332 41.6576 22.2051 42.8932 21.9528 44.2419C21.7004 45.5906 21.2922 46.8537 20.7309 48.0343C20.1697 49.2148 19.4113 50.1965 18.4555 50.984C17.5013 51.77 16.2933 52.1645 14.8314 52.1645C12.8083 52.1645 11.0253 51.4336 9.48085 49.9732C7.93639 48.5129 6.64424 46.7574 5.6044 44.7068C4.56457 42.6562 3.77858 40.4939 3.2449 38.2185C2.71122 35.9431 2.44361 33.9904 2.44361 32.3618V3.20208L8.76367 11.3342C11.0666 14.3405 13.2288 17.457 15.2519 20.6881ZM10.4488 0H0V53.2579C1.62857 55.336 3.51097 56.9799 5.64569 58.1879C7.78041 59.396 10.1965 60 12.8924 60C15.0837 60 17.0074 59.607 18.665 58.8195C20.3227 58.0335 21.6989 56.9646 22.7938 55.6174C23.8887 54.2687 24.7175 52.6967 25.2787 50.8984C25.8399 49.1001 26.1213 47.1626 26.1213 45.0845C26.1213 42.0506 25.6579 39.0458 24.7313 36.067C23.8046 33.0897 22.6378 30.1827 21.234 27.3446C19.8287 24.508 18.2843 21.7402 16.5991 19.0443L11.8801 11.2073L12.0483 11.0391L18.6635 21.7417L25.6151 32.2762L34.5485 45.7176L43.7342 58.9907H54.183L10.4488 0Z" fill={color} />
      </svg>
    )
  }
}


export { Logo, LogoMark }